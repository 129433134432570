import { useI18nSetLocale } from '~/composables/useI18nSetLocale';
import { getAvatarSrc, getInitials } from '~/composables/getAvatarSrc';

export const useUserStore = defineStore('userStore', {
  state: (): UserState => ({
    profile: {
      id: '',
      trashboardId: '',
      email: '',
      firstName: '',
      lastName: '',
      position: '',
      phoneNumber: {
        national: '',
        country: '',
      },
      language: '',
    },
    password: {
      modals: {
        update: false,
      },
    },
    twoFactorAuthentication: {
      enabled: false,
      qrCodeSvg: '',
      qrCodeLoaded: false,
      otpCode: '',
      loading: false,
      step: 1,
      error: '',
      recoveryCodes: [],
      recoveryCodesLoaded: false,
      modals: {
        enable: false,
        disable: false,
      },
    },
  }),
  actions: {
    setUserId(id: string) {
      this.profile.id = id;
    },
    async fetchUserData() {
      await $api<UserDataResponse>(`/api/v1/users/${this.profile.id}`)
        .then(async (data) => {
          await this.setUserData(data);
          return data;
        })
        .catch((error) => {
          useErrorNotify(error.data);
        });
    },
    updateUserData(profileForm: UserProfileForm) {
      return $api<UserDataResponse>(`/api/v1/users/${this.profile.id}`, {
        method: 'PATCH',
        body: {
          email: profileForm.email,
          first_name: profileForm.firstName ?? '',
          last_name: profileForm.lastName ?? '',
          position: profileForm.position ?? '',
          phone_number: {
            country: profileForm.phoneNumberCountry ?? 'DE',
            national: profileForm.phoneNumberNational ?? '',
          },
          language: profileForm.language,
        },
      }).then(async (data) => {
        await this.setUserData(data);
        useNotify(
          'success',
          'user.profile.settings.update.success.title',
          'user.profile.settings.update.success.description',
        );
        return data;
      });
    },
    async setUserData(data: UserDataResponse) {
      this.profile = {
        id: data.data.id,
        trashboardId: data.data.trashboardId,
        email: data.data.email,
        firstName: data.data.firstName ?? '',
        lastName: data.data.lastName ?? '',
        position: data.data.position ?? '',
        phoneNumber: {
          national: data.data.phoneNumber?.national ?? '',
          country: data.data.phoneNumber?.country ?? 'DE',
        },
        language: data.data.language ?? 'en',
      };

      this.twoFactorAuthentication.enabled = data.data.twoFactorEnabled as boolean;

      if (!['en', 'de'].includes(this.profile.language as string)) {
        this.profile.language = 'en';
      }

      await useI18nSetLocale(this.profile.language as string);
    },
    updatePassword(currentPassword: string, newPassword: string) {
      return $api<PasswordDataResponse>('/api/v1/users/update-password', {
        method: 'PATCH',
        body: {
          password: currentPassword,
          new_password: newPassword,
        },
      });
    },
    async enableTwoFactorAuthentication() {
      const { error } = await useCustomFetch(`/api/v1/users/${this.userId}/2fa`, {
        method: 'POST',
      });

      if (error.value != null) {
        useErrorNotify(error.value.data);
      }
    },
    async getTwoFactorAuthenticationQrCode() {
      this.twoFactorAuthentication.qrCodeLoaded = false;
      const { data, error } = await useCustomFetch<TwoFactorAuthenticationQrCodeResponse>(
        `/api/v1/users/${this.userId}/2fa/qr-code`,
      );

      if (data.value !== null) {
        this.twoFactorAuthentication.qrCodeSvg = data.value.svg;
        this.twoFactorAuthentication.qrCodeLoaded = true;
      }

      if (error.value != null) {
        useErrorNotify(error.value.data);
      }
    },
    async confirmTwoFactorCode() {
      this.twoFactorAuthentication.loading = true;
      const { pending, error, status } = await useCustomFetch(
        `/api/v1/users/${this.userId}/2fa/confirm`,
        { method: 'POST', body: { code: this.twoFactorAuthentication.otpCode } },
      );

      this.twoFactorAuthentication.loading = pending.value;

      if (status.value === 'success') {
        this.twoFactorAuthentication.step = 2;
      }

      if (error.value !== null) {
        this.twoFactorAuthentication.error = error.value?.data.errors.exception[0].message;
      }
    },
    async getRecoveryCodes() {
      this.twoFactorAuthentication.recoveryCodesLoaded = false;
      const { data, error } = await useCustomFetch(
        `/api/v1/users/${this.userId}/2fa/recovery-codes`,
      );

      if (data.value != null) {
        this.twoFactorAuthentication.recoveryCodesLoaded = true;
        this.twoFactorAuthentication.recoveryCodes = data.value as string[];
      }

      if (error.value != null) {
        useErrorNotify(error.value.data);
      }
    },
    async deactivateTwoFactorAuthentication() {
      const { data, error } = await useCustomFetch(
        `/api/v1/users/${this.userId}/2fa/${this.twoFactorAuthentication.otpCode}`,
        {
          method: 'DELETE',
        },
      );

      if (data.value != null) {
        this.twoFactorAuthentication.enabled = false;
      }

      if (error.value != null) {
        useErrorNotify(error.value.data);
      }
    },
  },
  getters: {
    userId: (state) => state.profile.id,
    name: (state) => `${state.profile.firstName} ${state.profile.lastName}`,
    initials: (state) => getInitials(state.profile.firstName, state.profile.lastName),
    userObject: (state) => state.profile,
    gravatarUrl: (state) => getAvatarSrc(state.profile.email),
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
