/**
 * Simple directive to get roles from the auth store and check if the role is
 * allowed to see the feature/component based on the role.
 *
 * # v-feature
 * Checks if a user has access to a feature.
 * @param {String} feature - The name of the feature to check
 *
 * Can be used like this:
 * <div v-feature="'featureA'">My secret feature</div>
 *
 * Please be aware that you have to set the feature name in single quotes.
 */
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('feature', {
    mounted(el, binding) {
      const authStore = useAuthStore();
      const roles = authStore.getRoles;

      if (!isFeatureAccessibleForRoles(binding.value, roles)) {
        el.parentNode.removeChild(el);
      }
    },
  });
});
