export type NestedObject = { [key: string]: any };

export function convertToCamelCase(str: string): string {
  return str.replace(/([-_][a-zA-Z])/g, (g) => g.toUpperCase().replace(/[-_]/, ''));
}

function convertKeysToCamelCase(obj: NestedObject): NestedObject {
  const camelCaseObj: NestedObject = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (Array.isArray(value)) {
      // If the value is an array, recursively convert each element
      camelCaseObj[convertToCamelCase(key)] = value.map((item: any) =>
        typeof item === 'object' && item !== null ? convertKeysToCamelCase(item) : item,
      );
    } else if (typeof value === 'object' && value !== null) {
      // Recursively convert nested objects
      camelCaseObj[convertToCamelCase(key)] = convertKeysToCamelCase(value);
    } else {
      camelCaseObj[convertToCamelCase(key)] = value;
    }
  });

  return camelCaseObj;
}

export function useChangeKeys(obj: NestedObject) {
  if (obj !== undefined) {
    return convertKeysToCamelCase(obj);
  }
  return obj;
}
