import { useAuthStore } from '~/stores/auth';
import { routesFeatureMap } from '~/config/features';

export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore();
  if (Object.prototype.hasOwnProperty.call(routesFeatureMap, to.path)) {
    const feature = routesFeatureMap[to.path];
    if (!isFeatureAccessibleForRoles(feature, authStore.getRoles)) {
      useNotify('error', 'authentication.error', 'authentication.no_access');
      if (to.path === from.path) {
        return navigateTo('/');
      }
      return abortNavigation();
    }
  }
  return true;
});
