import { features } from '~/config/features';

export function isFeatureAccessibleForRoles(featureName: string, roles: UserRole[]): boolean {
  const allUserRoles = roles.map((value) => value.name);
  const allowedRoles = features[featureName];

  if (allUserRoles.includes('SUPER_ADMIN')) {
    return true;
  }

  return allUserRoles.some((role) => allowedRoles.includes(role));
}
