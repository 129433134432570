import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBoxesBoxCard, LazyBoxesBoxFilterDropdown, LazyBoxesBoxStatusIcon, LazyBoxesList, LazyBoxesTable } from '#components'
const lazyGlobalComponents = [
  ["BoxesBoxCard", LazyBoxesBoxCard],
["BoxesBoxFilterDropdown", LazyBoxesBoxFilterDropdown],
["BoxesBoxStatusIcon", LazyBoxesBoxStatusIcon],
["BoxesList", LazyBoxesList],
["BoxesTable", LazyBoxesTable],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
