import type { LocationQuery } from 'vue-router';
import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import type { Auth, JwtPayloadRb, RefreshTokenResponse } from '~/interfaces/Auth';
import type { UserRole } from '~/interfaces/User';

export const useAuthStore = defineStore('authStore', {
  state: (): Auth => ({
    user: {
      id: '',
      trashboardId: '',
      organization: '',
      roles: [],
    },
    token: null,
    timeout: null,
  }),
  actions: {
    checkForToken(routeQuery: LocationQuery) {
      const { token } = routeQuery;
      if (token !== undefined && token !== null) {
        this.token = token.toString();
        this.parseToken();
        this.startTimeout();
      }
    },
    parseToken() {
      if (this.token != null) {
        const decoded = jwtDecode<JwtPayloadRb>(this.token);
        this.user = {
          id: decoded.sub,
          trashboardId: decoded.trashboard_id,
          organization: decoded.organization,
          roles: decoded.roles,
        };
      }
    },
    async refreshToken() {
      await $api<RefreshTokenResponse>('/api/v1/refresh-token', {}, false)
        .then((data) => {
          this.token = data.accessToken;
          this.startTimeout();
          this.$persist();
          return data;
        })
        .catch(() => {
          this.logout();
        });
    },
    startTimeout() {
      this.timeout = window.setTimeout(this.refreshToken, 1500000);
    },
    logout() {
      const config = useRuntimeConfig();
      this.user = {
        id: '',
        trashboardId: '',
        organization: '',
        roles: [],
      };
      this.token = null;
      this.$persist();
      navigateTo(config.public.loginPageUrl, { external: true });
    },
  },
  getters: {
    isLoggedIn: (state) => state.token !== null && state.token.length > 0,
    getToken: (state) => state.token,
    getUser: (state) => state.user,
    getUserId: (state) => state.user?.id,
    getUserTrashboardId: (state) => state.user?.trashboardId,
    getUserOrganizationId: (state) => state.user?.organization,
    getRoles: (state): UserRole[] => {
      if (state.user) {
        return state.user.roles.filter(
          (role) => role.expires_at === null || dayjs().isSameOrBefore(role.expires_at),
        );
      }
      return [];
    },
  },
  persist: {
    storage: persistedState.sessionStorage,
    paths: ['token'],
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
