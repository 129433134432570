export default defineNuxtPlugin(() => {
  if (!Array.prototype.remove) {
    /* eslint-disable no-extend-native */
    Object.defineProperty(Array.prototype, 'remove', {
      enumerable: false,
      writable: false,
      configurable: false,
      value: function remove<T>(elem: T): T[] {
        return this.filter((e: T) => e !== elem);
      },
    });
  }
});

// https://stackoverflow.com/questions/64522463/how-to-properly-extend-array-prototype-in-typescript-4-0-3
