import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware((to) => {
  const authStore = useAuthStore();
  const config = useRuntimeConfig();
  authStore.checkForToken(to.query);

  if (!authStore.isLoggedIn) {
    navigateTo(config.public.loginPageUrl, { external: true });
  } else if (authStore.getUserId === '') {
    authStore.parseToken();
  }
});
