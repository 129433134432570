import { configure, defineRule } from 'vee-validate';
import type { VueI18n } from 'vue-i18n';
import { all } from '@vee-validate/rules'; // https://github.com/logaretm/vee-validate/tree/main/packages/rules
import parsePhoneNumber, { type CountryCode } from 'libphonenumber-js';

function isEmpty(value: unknown): boolean {
  if (value === null || value === undefined || value === '') {
    return true;
  }

  if (Array.isArray(value) && value.length === 0) {
    return true;
  }

  return false;
}

function matchRegex(pattern: RegExp, value: string): boolean {
  if (isEmpty(value)) {
    return true;
  }
  return pattern.test(value);
}

export default defineNuxtPlugin((nuxtApp) => {
  Object.keys(all).forEach((rule) => {
    const ruleKey = rule;
    const ruleFn = all[ruleKey];
    defineRule(ruleKey, ruleFn);
  });

  defineRule('phone', (value: string, [target]: [string], ctx) => {
    if (isEmpty(value)) {
      return true;
    }

    const phoneNumber = parsePhoneNumber(value, {
      defaultCountry: ctx.form[target] as CountryCode,
      extract: false,
    });
    return Boolean(phoneNumber && phoneNumber?.isValid());
  });

  defineRule('has_upper_and_lowercase_letters', (value: string) =>
    matchRegex(/(?=.*[a-z])(?=.*[A-Z])/, value),
  );

  defineRule('has_number', (value: string) => matchRegex(/\d/, value));

  defineRule('has_special_char', (value: string) =>
    matchRegex(/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, value),
  );

  configure({
    validateOnBlur: true,
    validateOnChange: false,
    validateOnModelUpdate: false,
    validateOnInput: false,
    generateMessage: (context) => {
      const i18n = nuxtApp.$i18n as VueI18n;
      const defaultTranslation = i18n.t('validation.general_error', {
        fieldName: context.label,
        fieldValue: context.value,
      });
      return i18n.t(`validation.${context.rule?.name}`, defaultTranslation, {
        named: {
          fieldName: context.label,
          fieldValue: context.value,
          ruleParams: context.rule?.params[0],
        },
      });
    },
  });
});
