import type { VueI18n } from 'vue-i18n';

export default defineNuxtPlugin((nuxtApp) => {
  const i18n = nuxtApp.$i18n as VueI18n;
  const dayjs = useDayjs();

  // called right before setting a new locale
  nuxtApp.hook('i18n:beforeLocaleSwitch', ({ newLocale }) => {
    dayjs.locale(newLocale);
  });

  return {
    provide: {
      t: (key: string, defaultTranslation = '') => {
        const regex = /.+\|default:.+/;
        let standardTranslation: string = defaultTranslation;
        let tmpKey: string = key;

        if (regex.test(key)) {
          [tmpKey, standardTranslation] = tmpKey.split('|default:');
        }

        return i18n.t(tmpKey, standardTranslation);
      },
    },
  };
});
