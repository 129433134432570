import type { ToastType } from 'vue3-toastify';
import { useToastStore } from '~/stores/toast';

export const defaultToast: RbToast = {
  type: 'default',
  title: '',
  description: '',
  options: {
    autoClose: true,
    iconName: '',
    duration: 8000,
    titleData: {},
    descriptionData: {},
  },
};

export const useNotify = (
  type: ToastType = defaultToast.type,
  title: string = defaultToast.title,
  description: string | string[] = defaultToast.description,
  options: RbToastOptions = {
    autoClose: defaultToast.options.autoClose,
    iconName: defaultToast.options.iconName,
    duration: defaultToast.options.duration,
    titleData: defaultToast.options.titleData,
    descriptionData: defaultToast.options.descriptionData,
  },
) => {
  const toastStore = useToastStore();
  toastStore.addToast({
    type,
    title,
    description,
    options: {
      autoClose: options.autoClose ?? defaultToast.options.autoClose,
      iconName: options.iconName ?? defaultToast.options.iconName,
      duration: options.duration ?? defaultToast.options.duration,
      titleData: options.titleData ?? defaultToast.options.titleData,
      descriptionData: options.descriptionData ?? defaultToast.options.descriptionData,
    },
  });
};

export const useErrorNotify = (errorResponse: ErrorResponse) => {
  const { $t } = useNuxtApp();
  const description: string[] = [];
  if (errorResponse !== undefined && 'errors' in errorResponse) {
    Object.values(errorResponse.errors)
      .flatMap((errorDetails) => errorDetails)
      .forEach((errorDetail) => {
        description.push($t(errorDetail.i18n ?? errorDetail.message, errorDetail.message));
      });
    const toastStore = useToastStore();
    toastStore.addToast({
      type: 'error',
      title: 'global.error',
      description,
      options: defaultToast.options,
    });
  }
};
