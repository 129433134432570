import md5 from 'crypto-js/md5';

export const getAvatarSrc = (email: String) => {
  const md5email = md5(email.toLowerCase());
  // return 'https://i.pinimg.com/736x/b4/93/96/b493966e2bece31ba77fd9c9b91e736e.jpg';
  return `https://www.gravatar.com/avatar/${md5email}?s=112&d=404`;
};

export const gravatarLoads = (src: string, onload: Function, onerror: Function) => {
  const img = new Image();

  img.src = src;
  img.onload = () => {
    onload();
  };
  img.onerror = () => {
    onerror();
  };
};

export const getInitials = (firstName: string, lastName: string) => {
  if (firstName && lastName && firstName !== undefined && lastName !== undefined) {
    return firstName.substring(0, 1) + lastName.substring(0, 1).toUpperCase();
  }
  return null;
};
